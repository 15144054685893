clearfix() {
  &:before, &:after {
    content: '';
    display: table;
    clear: both;
  }
}

media-gt($resolution) {
  @media only screen and (min-width: $resolution) {
    {block}
  }
}

media-lt($resolution) {
  @media only screen and (max-width: $resolution) {
    {block}
  }
}
