@critical homepage-critical.css {
  .homepage-vitrine {
    display: flex;
    align-items: flex-end;

    @media $mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .product {
      max-width: 30%;

      @media $mobile {
        max-width: 100%;
      }
    }

    .product + .product {
      margin-left: 5%;

      @media $mobile {
        margin-left: 0;
        margin-top: 40px;
      }
    }

    .product__name {
      text-transform: uppercase;
      font-weight: $medium;
      margin-bottom: .5em;
    }

    .product__value {
      font-size: 1.4rem;
    }
  }
}
