@critical {
  .footer {
    margin-top: 65px;

    @media $mobile {
      margin-top: 0;
    }

    .wrapper {
      padding-top: 65px;
      padding-bottom: 90px;
      position: relative;

      @media $mobile {
        padding-top: 0;
        padding-bottom: 45px;
      }
    }

    .wrapper:before {
      content: '';
      display: block;
      width: calc(100% - 10vw - 90px); height: 1px;
      background: $gray;
      position: absolute;
      left: 50%; top: 0;
      transform: translateX(-50%);

      @media only screen and (max-width: $wrapper-width + 19px) {
        width: calc(100% - 90px);
      }

      @media $mobile {
        width: 100%;
        top: 100%;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .footer__caption {
    font-size: 3rem;
    font-weight: $medium;
    margin-bottom: 1.5em;

    @media $small-handheld {
      font-size: 2.4rem;
    }

    @media $mobile {
      font-size: 2rem;
    }
  }

  .footer__subcaption {
    font-size: 2.4rem;
    margin-bottom: 1.5em;

    @media $small-handheld {
      font-size: 2rem;
    }

    @media $mobile {
      font-size: 1.6rem;

      br {
        display: none;
      }
    }
  }

  .footer__contact {
    margin-bottom: 3em;

    @media $mobile {
      font-size: 1.4rem;

      br {
        display: none;
      }
    }

    .item {
      a {
        padding-left: 32px;
        font-size: 2rem;
        font-weight: $medium;
      }
    }

    .item--phone {
      a {
        background: url(/i/phone-icon.svg) no-repeat 0 50%;
        color: $dark;
      }
    }

    .item--email {
      a {
        background: url(/i/email-icon.svg) no-repeat 0 50%;
        color: $blue;
        text-decoration: underline;
      }
    }

    .item + .item {
      margin-top: .5em;
    }
  }

  .footer__contact-text {
    margin-bottom: 1em;

    @media $mobile {
      margin-bottom: 1.5em;
    }
  }

  .footer__address {
    @media $mobile {
      font-size: 1.4rem;

      br {
        display: none;
      }
    }
  }
}

.mobile-bottom-logo {
  @media $not-mobile {
    display: none;
  }

  @media $mobile {
    display: block;
    width: width('i/dkl-logo.svg'); height: height('i/dkl-logo.svg');
    background: url(../../../i/dkl-logo.svg) no-repeat 50%;
    font-size: 0;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 60px;
    margin-bottom: 70px;
  }
}
