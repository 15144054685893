.currency {
  span {
    display: none;
  }

  &:before {
    content: 'i';
    font-family: 'rouble';
  }
}
