// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }

  .r-main {
    float: right;
    width: 49%;
  }
}

@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.flex-group {
  display: flex;

  &.justify {
    justify-content: space-between;
  }
}

@critical {
  .wrapper {
    box-sizing: border-box;

    @media only screen and (min-width: $wrapper-width + 20px) {
      padding-left: 5vw;
      padding-right: 5vw;
    }

    @media only screen and (max-width: $wrapper-width + 19px) {
      @media $not-mobile {
        margin-left: 20px;
        margin-right: 20px;
      }

      @media $mobile {
        margin-left: 18px;
        margin-right: 18px;
      }
    }
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

@critical {
  .hidden {
    display: none !important;
  }
}


// Devices display

@critical {
  @media $handheld {
    .only-desktop {
      display: none !important;
    }
  }

  @media $not-mobile {
    .only-mobile {
      display: none !important;
    }
  }

  @media $not-small-mobile {
    .only-small-mobile {
      display: none !important;
    }
  }

  @media $not-small-handheld {
    .only-small-handheld { // mobile + portrait-tablet
      display: none !important;
    }
  }

  @media $desktop {
    .not-desktop { // mobile + tablet
      display: none !important;
    }
  }

  @media $mobile {
    .not-mobile { // desktop + tablet
      display: none !important;
    }
  }

  @media $small-mobile {
    .not-small-mobile { // desktop + tablet
      display: none !important;
    }
  }

  @media $small-handheld {
    .not-small-handheld { // desktop + landscape tablet
      display: none !important;
    }
  }
}
