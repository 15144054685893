@critical {
  .btn {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background: none;
    cursor: pointer;
    user-select: none;
    transition: all .25s ease;
  }
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover {
    color: $black;
  }
}

.black-btn {
  background-color: $black;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($black, 10);
  }
}


// Sizes

.wide-btn {
  width: 100%;
}
